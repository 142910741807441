<template>
    <cui-acl  :roles="['HRseeUsersDepartment', 'HRseeUsersAll', 'HRseeUsersProject', 'HRseeUsersProjectDepartment']" redirect v-if="loaded">
    <div v-if="underOffboarding">
      <offboarding-form :data="data" @cancelOffboarding="underOffboarding = false"/>
    </div>
    <div v-else-if="underNewPos">
      <reactivation-form :data="data" @cancelReactivation="underNewPos = false"/>
    </div>
    <div v-else>
      <EmployeeNavigation
      :selectedTab="selectedTab"
      @switchEmployee="switchEmployee"
      @changeTab="changeTab"
    />
    <div v-if="selectedTab === 'overview'">
    <div class="row" >
      <div class="col-xl-4 col-lg-12">
        <div class="card">
          <div class="card-body">
            <kit-general-10v1 :name="data.fullName" :position="data.title" :department="data.department" :companies="data.companies" :status="data.status" :imageUrl="data.imageUrl" :uid="data.uid" :type="data.type" />
            <div>
              <div v-if="data.status == 'onboarding'">
                <div>
                  <a-popconfirm
                    :title="'Er du sikker du ønsker å aktivere brukeren'"
                    ok-text="Ja"
                    cancel-text="Nei"
                    @confirm="activateUser(data)"
                  >
                    <a-button type="primary"  class="mt-3" :loading="loadingActivate">Aktiver bruker</a-button>
                  </a-popconfirm>
                </div>
                <div v-if="['HReditUser'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
                  <a-popconfirm
                    :title="'Er du sikker du ønsker å slette brukeren'"
                    ok-text="Ja"
                    cancel-text="Nei"
                    @confirm="deleteUser(data)"
                  >
                    <a-button type="secondary"  class="mt-3">Slett bruker</a-button>
                  </a-popconfirm>
                </div>
              </div>
              <div v-if="['HRoffboardUser'].some(r => $store.state.user.roles.indexOf(r) >= 0) && data.status === 'active'">
                <a-button
                  type="primary"
                  class="mt-3"
                  @click="underOffboarding = true"
                  >Offboard bruker</a-button>
              </div>
              <div v-if="['HRcreateUsersDepartment', 'HRcreateUsersAll'].some(r => $store.state.user.roles.indexOf(r) >= 0) && data.status === 'inactive'">
                <a-button
                  type="primary"
                  class="mt-3"
                  @click="underNewPos = true"
                  >Reaktiver Bruker</a-button>
              </div>
              <div v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0) && data.status == 'active'">
                <div v-if="!data.mailingListID">
                  <a-button type="primary"  class="mt-3" @click="activateMailing(data)" :loading="loadingActivate">Add to mailing</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <users-form :data="data" />
          </div>
        </div>
        <!-- <div class="card" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
          <div class="card-body">
            <h5 class="text-black mt-2 mb-3">
              <strong>Utstyr</strong>
            </h5>
            <div v-for="(equipment, index) in data.equipment" :key="index" class="mt-2">
              <strong>{{ equipment.type }}</strong><br>
              <span>{{ equipment.description }}</span>
            </div>
            <a-button style="width: 150px;" type="primary"  class="mt-3" @click="newEquipment">Nytt Utstyr</a-button>
          </div>
        </div> -->
        <div class="card">
          <div class="card-body">
            <user-netofkin :data="data" />
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-12">
        <div class="card">
          <div class="card-body">
            <about-user-form :data="data">
              <contact-info :data="data" />
            </about-user-form>
          </div>
        </div>
        <div class="card" v-if="data.type === 'consultant'">
          <div class="card-body">
            <consultant-info :consultant="data.consultant" :uid="data.uid"/>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="text-black mt-2 mb-3">
              <strong>Notater</strong>
            </h5>
            <a-textarea placeholder="Notater" :rows="4" v-model="data.notes"/>
            <a-button style="width: 150px;" type="primary"  class="mt-3" @click="updateNotes">Oppdater</a-button>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <positions-form :data="data" @newPosition="underNewPos = true" />
          </div>
        </div>
      </div>
    </div>
    </div>
    <div v-if="selectedTab === 'systems'">
      <systems :data="data" />
    </div>
    <div v-if="selectedTab === 'documents'">
      <div class="card" v-if="['HRseeUsersDocuments'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
        <div class="card-body">
          <document-area :reference="data.employeeID"/>
        </div>
      </div>
    </div>
    <div v-if="selectedTab === 'roles'">
      <div class="row" v-if="['userAdmin'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
      <div class="col-xl-12 col-lg-12">
        <user-roles :uid="data.uid" :oldRoles="data.roles"/>
      </div>
      </div>
    </div>
    <div v-if="selectedTab === 'log'">
      <div class="card" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
        <div class="card-body">
          <activity-forms :data="data" />
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">
            <strong>Verving (midlertidig)</strong>
          </h5>
          <a-button style="width: 150px;" type="primary"  class="mt-3" @click="() => {
            newRecruit = !newRecruit
            if (referrers.length === 0) {
              getReferrers()
            }
            }">Ny Verv</a-button>
          <div v-if="newRecruit">
            <a-form-item label="Referert av">
              <a-auto-complete
              v-model="data.referredBy"
              :data-source="referrers"
              :filter-option="filterOption"
              placeholder="finn ansatt..."
            />
            </a-form-item>
            <a-button @click="createRecruitment(data, referrers.find(user => user.value === data.referredBy))">Registrer</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </cui-acl>
</template>
<script>
import { firestore, functions } from '@/services/firebase'
import AboutUserForm from '@/components/custom/forms/userinfo'
import ContactInfo from '@/components/custom/forms/contactInfo'
import ConsultantInfo from '@/components/custom/user/consultantInfo'
import UsersForm from '@/components/custom/forms/users'
import activityForms from '@/components/custom/forms/activity'
import positionsForm from '@/components/custom/user/positions'
import userNetofkin from '@/components/custom/user/netofkin'
import KitGeneral10v1 from '@/components/kit/widgets/General/10v1/index'
import cuiAcl from '@/components/cleanui/system/ACL'
import documentArea from '@/components/custom/user/documents'
import offboardingForm from '@/components/custom/user/offboarding'
import reactivationForm from '@/components/custom/user/newPosition'
import Systems from '@/components/custom/user/systems'
import EmployeeNavigation from '@/components/custom/user/EmployeeNavigation'

import userRoles from '@/components/custom/user/roles'
import moment from 'moment'
import { createRecruitment } from '@/services/common/payroll'

import { openNotification, addtoMailinglist, sendEmail, createTripletexCSV, createTripletexAPI, delete365API } from '@/services/powerAutomate'

export default {
  components: {
    KitGeneral10v1,
    AboutUserForm,
    cuiAcl,
    UsersForm,
    positionsForm,
    activityForms,
    userNetofkin,
    documentArea,
    userRoles,
    offboardingForm,
    reactivationForm,
    ContactInfo,
    ConsultantInfo,
    Systems,
    EmployeeNavigation,
  },
  data() {
    return {
      activeKey: '1',
      dateFormat: 'DD.MM.YYYY',
      data: null,
      edit: false,
      loaded: false,
      loadingActivate: false,
      underOffboarding: false,
      underNewPos: false,
      nextId: '',
      prevId: '',
      newRecruit: false,
      referrers: [],
      selectedTab: 'overview',
    }
  },
  methods: {
    moment,
    createRecruitment,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    updateNotes() {
      firestore.collection('users').doc(this.data.uid).update({
        notes: this.data.notes,
      }).then(() => openNotification('Noter lagret')).catch(err => openNotification('Feil ved lagring av noter', err))
    },
    async deleteUser(data) {
      delete365API(data)
      firestore.collection('users').doc(data.uid)
        .delete()
        .then(() => {
          openNotification('Bruker Slettet', `Brukeren til ${data.fullName} har blitt slettet`)
          this.$router.push({ path: '/hr/persons/onboarding' })
        })
    },
    async activateUser(data) {
      if (this.data.adress && this.data.bankAccount && this.data.city && this.data.email && this.data.name && this.data.surname && this.data.personalNumber && this.data.zip && this.data.employeeID) {
        this.loadingActivate = true
        addtoMailinglist(data.fullName, data.email, data.department)
          .then(res => {
            this.data.mailingListID = res.data.id
            this.data.startDate = this.data.positions.at(-1).startDate
            this.data.status = 'active'
            firestore.collection('users').doc(this.data.uid)
              .update({
                mailingListID: this.data.mailingListID,
                status: this.data.status,
              })
            if (data.type === 'employee') {
              return createTripletexCSV(this.data)
            } else {
              return true
            }
          }).then(() => {
            openNotification('Bruker Aktivert')
            if (data.type === 'employee') {
              return createTripletexAPI(this.data)
            } else {
              return true
            }
          }).catch(err => {
            console.log(err)
            openNotification('Feil ved aktivering av bruker. IT har mottatt varsel og vil ordne fortløpende')
            sendEmail('it@skygruppen.no', '', `SKYPORTAL: Error while activating employee ${data.fullName}`,
            `<html><body><p>Hi,<br>
            An error occured while activating ${data.fullName} on SKYPORTAL.<br>
            The error that got logged is:<br>
            ${err}<br>
            The error happened on ${this.$store.state.user.name}'s user.<br>
            Kind regards<br><br>
            IT Sky Gruppen</p></body></html>`)
          }).finally(() => {
            this.loadingActivate = false
          })
      } else {
        openNotification('Mangler info på ansatt for å opprette Tripletex Bruker')
        console.log(this.data.adress, this.data.bankAccount, this.data.city, this.data.email, this.data.name && this.data.surname && this.data.personalNumber && this.data.zip && this.data.employeeID)
      }
    },
    async activateMailing(data) {
      this.loadingActivate = true
      addtoMailinglist(data.fullName, data.email, data.department)
        .then(res => {
          this.data.mailingListID = res.data.id
          firestore.collection('users').doc(this.data.uid)
            .update({
              mailingListID: this.data.mailingListID,
            })
          openNotification('User added to mailing')
        }).catch(err => (
          console.log(err)
        )).finally(() => {
          this.loadingActivate = false
        })
    },
    newEquipment() {},
    switchEmployee(op) {
      if (op === 'next') {
        this.$router.push({ path: `/hr/person/${this.nextId}` })
      } else if (op === 'prev') {
        this.$router.push({ path: `/hr/person/${this.prevId}` })
      }
      this.render()
    },
    changeTab(tab) {
      this.selectedTab = tab
    },
    render() {
      firestore.collection('users').doc(this.$route.params.uid).get()
        .then(doc => {
          this.data = doc.data()
          if (this.data.status === 'inactive' && !['HRseePreviousEmployment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
            this.$router.push({ path: '/auth/404' })
          }
          this.data.referredBy = this.data.referredBy ? this.data.referredBy : ''
          this.data.uid = doc.id
          this.loaded = true
          if (!this.data.roles) {
            this.data.roles = ['']
          }
          if (['HRseeUsersAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
            return firestore.collection('users').where('status', '==', doc.data().status).orderBy('fullName', 'asc').get()
          } else if (['HRseeUsersDepartment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
            return firestore.collection('users').where('status', '==', doc.data().status).where('department', '==', this.$store.state.user.department).orderBy('fullName', 'asc').get()
          } else if (['HRseeUsersProject'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
            return firestore.collection('users').where('status', '==', doc.data().status).where('project', '==', this.$store.state.user.project).orderBy('fullName', 'asc').get()
          }
        })
        .then(snapshot => {
          const temp = []
          snapshot.forEach(doc => {
            temp.push(doc.id)
          })
          const index = temp.indexOf(this.$route.params.uid)
          if (index === 0) {
            this.prevId = temp[temp.length - 1]
          } else {
            this.prevId = temp[index - 1]
          }
          if (index === (temp.length - 1)) {
            this.nextId = temp[0]
          } else {
            this.nextId = temp[index + 1]
          }
        })
    },
    getReferrers() {
      firestore.collection('users').where('status', '==', 'active').where('companies', 'array-contains', 'Skycall').get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const user = {
              value: String(doc.data().employeeID),
              text: String(doc.data().fullName),
              key: String(doc.data().employeeID),
            }
            this.referrers.push(user)
          })
        })
    },
    deactivate365() {
      // launch deactivate365 from firebase functions
      const deactivate365 = functions.httpsCallable('testDeactivate365')
      deactivate365(this.data)
        .then(() => {
          openNotification('Office365 deaktivert')
        })
    },
  },
  mounted() {
    this.render()
  },
}
</script>
