<template>
  <div>
    <div>
      <a-button style="float: right;" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)" @click="toggleEdit">
        Endre Brukere
      </a-button>
      <h5 class="text-black mt-2 mb-3">
        <strong>Brukere</strong>
      </h5>
      <div v-if="!editUsers">
        <div v-if="data.companyEmail">
          <strong>Microsoft 365</strong><br>
          brukernavn: {{ data.companyEmail }}
        </div>
        <div v-if="data.vismaEmployee">
          <strong>Visma Employee</strong><br>
          status: {{ data.vismaEmployee }}
        </div>
        <div v-if="data.tripletexManual">
          <strong>Tripletex</strong><br>
          status: {{ data.tripletexManual }}
        </div>
        <div v-if="data.leadDesk">
          <strong>Leaddesk</strong><br>
          brukernavn: {{ data.leadDesk }}
        </div>
        <div v-if="data.talkmoreB2C">
          <strong>Talkmore Privat</strong><br>
          brukernavn: {{ data.talkmoreB2C }}
        </div>
        <div v-if="data.nextcomTalkmoreB2B">
          <strong>Nextcom Talkmore Bedrift</strong><br>
          brukernavn: {{ data.nextcomTalkmoreB2B }}
        </div>
        <div v-if="data.fjordkraftPortal">
          <strong>Webtabellen Fjordkraft</strong><br>
          brukernavn: {{ data.fjordkraftPortal }}
        </div>
        <div v-if="data.telenorFiber">
          <strong>Telenor Fiber Salesforce</strong><br>
          brukernavn: {{ data.telenorFiber }}
        </div>
        <br>
        <div v-if="['manageUsers'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
          <a-button style="width: 100px;" type="primary"  class="mr-3 mt-3" @click="toggleNew">Ny Bruker</a-button>
          <a-popconfirm
            :title="'Er du sikker du ønsker å sende epost for brukere'"
            ok-text="Ja"
            cancel-text="Nei"
            @confirm="sendUserEmail"
          >
            <a-button style="width: 180px;" type="secondary"  class="mr-3 mt-3" :loading="loading">Send e-post for brukere</a-button>
          </a-popconfirm>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="row">
          <div class="col-md-12">
            <strong>Microsoft 365</strong>
          </div>
          <div class="col-md-6">
            Brukernavn: {{ data.companyEmail }}<br>
            AADID: {{ data.AAD }}
          </div>
          <div class="col-md-6">
            <a-input placeholder="nav.ett" type="text" v-model="data.companyEmail" />
          </div>
          <div class="col-md-3">
            <a-button  type="secondary" style="width: 100%;" @click="deactivateOffice365">Deaktiver</a-button>
          </div>
          <div class="col-md-3">
            <a-button  type="secondary" style="width: 100%;" @click="createOffice365">Regenerer</a-button>
          </div>
          <div class="col-md-3">
            <a-button  type="secondary" style="width: 100%;" @click="getOffice365ID">Get AAD ID</a-button>
          </div>
          <div class="col-md-3">
            <a-button  type="secondary" style="width: 100%;" @click="deleteOffice365">Delete AAD</a-button>
          </div>
          </div>
        </div>
        <div v-if="data.vismaEmployee">
          <strong>Visma Employee</strong><br>
          <a-input type="text" v-model="data.vismaEmployee" />
        </div>
        <div v-if="data.tripletexManual">
          <strong>Tripletex</strong><br>
          <a-input type="text" v-model="data.tripletexManual" />
        </div>
        <div>
          <div class="row">
          <div class="col-md-12">
          <strong>Leaddesk</strong><br>
          </div>
          <div class="col-md-8">
            <a-input placeholder="nav.ett" type="text" v-model="data.leadDesk" />
          </div>
          <div class="col-md-4">
            <a-button style="width: 100%;" type="secondary" @click="deactivateLeaddesk">Deaktiver</a-button>
          </div>
          </div>
        </div>
        <div>
          <strong>Talkmore Privat</strong><br>
          <a-input placeholder="epxxxxx" type="text" v-model="data.talkmoreB2C" />
        </div>
        <div>
          <strong>Webtabellen Fjordkraft</strong><br>
          <a-input type="text" v-model="data.fjordkraftPortal" />
        </div>
        <div>
          <strong>Telenor Fiber Salesforce</strong><br>
          <a-input type="text" v-model="data.telenorFiber" />
        </div>
        <a-button style="width: 150px;" type="primary"  class="mt-2" @click="updateUser">Oppdater</a-button>
      </div>
    </div>
    <div class="mt-4" v-if="newUsers">
      <h5 class="text-black">
        <strong>Ny Bruker</strong>
      </h5>
      <div class="row">
        <div class="col-md-12 mb-2">
          <a-select
            show-search
            placeholder="Velg system"
            option-filter-prop="system"
            style="width: 200px"
            :filter-option="filterOption"
            @focus="handleFocus"
            @blur="handleBlur"
            @change="handleChange"
          >
            <a-select-option v-for="system in systems" :key="system">
              {{ system }}
            </a-select-option>
          </a-select>
        </div>
        <!-- Create Leaddesk user -->
        <div class="col-md-8" v-if="leadDesk">
          <div class="row">
            <div class="col-md-12">
              <a-input placeholder="nav.ett" type="text" v-model="leadDeskUser" />
            </div>
            <div class="col-md-12">
              <a-button style="width: 150px;" type="primary"  class="mr-3" @click="createLeaddesk" :loading="loading">Lag Bruker</a-button>
            </div>
          </div>
        </div>
        <!-- Create Microsoft 365 user -->
        <div class="col-md-8" v-if="office365">
          <div class="row">
            <div class="col-md-12">
              <a-input placeholder="nav.ett" type="text" v-model="data.companyEmail" />
            </div>
            <div class="col-md-12">
              <a-button style="width: 100%;" type="primary" @click="createOffice365" :loading="loading">Lag Bruker</a-button>
            </div>
          </div>
        </div>
        <!-- Order Talkmore Privat user -->
        <div class="col-md-8" v-if="talkmoreB2C">
          <div class="row">
            <div class="col-md-12">
              <a-button style="width: 100%;" type="primary" @click="orderTalkmoreB2C" :loading="loading">Bestill Brukere</a-button>
            </div>
          </div>
        </div>
        <!-- Order Telenor Workday user -->
        <div class="col-md-8" v-if="telenorWorkday">
          <div class="row">
            <div class="col-md-12">
              <a-button style="width: 100%;" type="primary" @click="orderTelenorWorkday" :loading="loading">Bestill Brukere</a-button>
            </div>
          </div>
        </div>
        <!-- Webtabellen -->
        <div class="col-md-8" v-if="fjordkraftPortal">
          <div class="row">
            <div class="col-md-12">
              <a-button style="width: 100%;" type="primary" @click="fjordkraftPortalOK" :loading="loading">Bruker Oprettett</a-button>
            </div>
          </div>
        </div>
         <!-- Order Telenor Fiber field salesforce user -->
        <div class="col-md-8" v-if="telenorFiberSalesforce">
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="Tilgangstype">
                <a-select
                show-search
                style="width: 100%"
                v-model="tnfibersf.role"
                >
                  <a-select-option value="Selger">
                    Selger
                  </a-select-option>
                  <a-select-option value="Teamleder">
                    Teamleder
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Systemer">
                <a-select
                show-search
                placeholder="Velg tilgang"
                style="width: 100%"
                mode="multiple"
                v-model="tnfibersf.systems"
                >
                  <a-select-option value="Salesforce">
                    Salesforce
                  </a-select-option>
                  <a-select-option value="Motimate">
                    Motimate
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Oppstartsdato">
                <a-date-picker  v-model="tnfibersf.startDate"/>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Tillegskommentar">
                <a-textarea placeholder="Notater" :rows="2" v-model="tnfibersf.comment"/>
              </a-form-item>
            </div>
            <div class="col-md-12 mt-3">
              <a-button style="width: 100%;" type="primary" @click="orderSalesforceTelenorFiber" :loading="loading">Bestill Brukere</a-button>
            </div>
          </div>
        </div>
         <!-- Order Telenor Fiber ID card -->
         <div class="col-md-12" v-if="telenorFiberID">
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="Leveringssted">
                <a-select
                show-search
                style="width: 100%"
                v-model="tnfiberId.delivery"
                >
                  <a-select-option value="SKYCALL AS v/Stian Abrahamsen - Tollbodgata 6, 4611 Kristiansand">
                    Kristiansand kontor
                  </a-select-option>
                  <a-select-option value="SKYCALL AS v/Vincent Tinella - Strandveien 13, 1366 Lysaker">
                    Oslo kontor
                  </a-select-option>
                  <a-select-option value="SKYCALL AS v/Eirik Hval - Fredrik Langes Gate 14, 9008 Tromsø">
                    Tromsø kontor
                  </a-select-option>
                  <a-select-option value="SKYCALL AS v/Thommas Bjerkvik - Vestre Rosten 77, 7075 Tiller">
                    Trondheim kontor
                  </a-select-option>
                  <a-select-option value="Hentes på Fornebu">
                    Hentes på Fornebu
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Legitimasjon">
                <a-upload :file-list="tnfiberId.fileList" :before-upload="beforeUpload" accept="image/png, image/jpeg">
                  <a-button> <a-icon type="upload" /> Last opp </a-button>
                </a-upload>
              </a-form-item>
            </div>
            <div class="col-md-12 mt-3">
              <a-button style="width: 100%;" type="primary" @click="orderIDTelenorFiber" :loading="loading">Bestill ID</a-button>
            </div>
          </div>
        </div>
        <!-- Create Visma Employee User -->
         <div class="col-md-12" v-if="vismaEmployee">
            <div class="col-md-12">
              <a-button style="width: 100%;" type="primary" @click="createVismaXML" :loading="loading">Bestill Bruker</a-button>
            </div>
        </div>
        <!-- Create Tripletex User -->
         <div class="col-md-12" v-if="tripletexManual">
            <div class="col-md-12">
              <a-button style="width: 100%;" type="primary" @click="createTripletexCSV" :loading="loading">Bestill Bruker</a-button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore, storage } from '@/services/firebase'
import { mapState } from 'vuex'
import moment from 'moment'
import imageCompression from 'browser-image-compression'

import { openNotification, createTripletexCSV, createLeaddeskAPI, create365API, get365AAD, sendEmail, vismaOnboarding, deactivate365API, deactivateLeaddeskAPI, delete365API } from '@/services/powerAutomate'
import { calculateGender } from '@/services/common/hr'

export default {
  props: ['data'],
  computed: {
    ...mapState(['user']),
  },
  data: () => {
    return {
      newUsers: false,
      systems: [],
      leadDesk: false,
      office365: false,
      talkmoreB2C: false,
      telenorWorkday: false,
      fjordkraftPortal: false,
      leadDeskUser: '',
      companyEmail: '',
      editUsers: false,
      loading: false,
      telenorFiber: false,
      vismaEmployee: false,
      tripletexManual: false,
      telenorFiberSalesforce: false,
      tnfibersf: {
        systems: ['Salesforce', 'Motimate'],
        role: 'Selger',
        startDate: moment(new Date(), 'YYYY-MM-DD'),
        comment: '',
      },
      telenorFiberID: false,
      tnfiberId: {
        delivery: '',
        fileList: [],
      },
      idImg: '',
    }
  },
  created() {
    this.removeExisting()
  },
  mounted() {
    if (['underDevelopment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      this.systems = this.systems.concat(['Microsoft 365', 'Visma Employee', 'Tripletex Manual', 'TelenorWorkday'])
    }
    if (['orderFK'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      this.systems = this.systems.concat(['Leaddesk Fjordkraft', 'Webtabellen Fjordkraft'])
    }
    if (['orderTNFIBER'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      this.systems = this.systems.concat(['Telenor Fiber Salesforce'])
      this.systems = this.systems.concat(['Telenor Fiber ID kort'])
    }
    if (['orderTMB2C'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      this.systems = this.systems.concat(['Talkmore Privat'])
    }
  },
  methods: {
    moment,
    removeExisting() {
      if (this.data.leadDesk) {
        this.systems = this.systems.filter(value => value !== 'Leaddesk Fjordkraft')
      }
      if (this.data.fjordkraftPortal) {
        this.systems = this.systems.filter(value => value !== 'Webtabellen Fjordkraft')
      }
      if (this.data.companyEmail) {
        this.systems = this.systems.filter(value => value !== 'Microsoft 365')
      }
      if (this.data.talkmoreB2C) {
        this.systems = this.systems.filter(value => value !== 'Talkmore Privat')
      }
      if (this.data.nextcomTalkmoreB2B) {
        this.systems = this.systems.filter(value => value !== 'Nextcom Talkmore Bedrift')
      }
      if (this.data.telenorFiber) {
        this.systems = this.systems.filter(value => value !== 'Telenor Fiber Salesforce')
      }
      if (this.data.telenorFiberID) {
        this.systems = this.systems.filter(value => value !== 'Telenor Fiber ID kort')
      }
      if (this.data.vismaEmployee) {
        this.systems = this.systems.filter(value => value !== 'Visma Employee')
      }
      if (this.data.tripletexManual) {
        this.systems = this.systems.filter(value => value !== 'Tripletex Manual')
      }
    },
    toggleNew() {
      this.newUsers = !this.newUsers
    },
    toggleEdit() {
      this.editUsers = !this.editUsers
    },
    updateUser() {
      const data = this.data
      data.fullName = data.name.concat(' ', data.surname)
      console.log(data)
      return firestore.collection('users').doc(data.uid).update(data).then(() => {
        openNotification('Brukere oppdatert')
        this.editUsers = false
      }).catch(err => {
        openNotification('Feil ved oppdatering av brukere')
        console.log(err)
      })
    },
    createLeaddesk() {
      this.loading = true
      this.data.leadDesk = this.leadDeskUser
      createLeaddeskAPI(this.data).then(() => {
        openNotification('Leaddesk Bruker Opprettet', '')
        this.updateUser()
      }).catch(() => {
        openNotification('Feil ved oprettelse av Leaddesk Bruker', '')
        this.data.leadDesk = ''
      }).finally(() => {
        this.loading = false
        this.removeExisting()
      })
    },
    deactivateLeaddesk() {
      const user = this.data.leadDesk
      this.data.leadDesk = ''
      this.loading = true
      deactivateLeaddeskAPI(user).then(() => {
        openNotification('Leaddesk Bruker deaktivert', '')
        this.updateUser()
      }).catch(() => {
        this.data.leadDesk = user
        openNotification('Feil ved deaktivering av bruker')
      }).finally(() => {
        this.loading = false
      })
    },
    createOffice365() {
      this.loading = true
      create365API(this.data).then(res => {
        console.log(res)
        openNotification('Microsoft 365 Bruker regenerert', '')
        this.updateUser()
      }).catch(() => {
        this.data.companyEmail = ''
        openNotification('Feil ved reaktivering av bruker')
      }).finally(() => {
        this.loading = false
        this.newUsers = false
        this.removeExisting()
      })
    },
    getOffice365ID() {
      get365AAD(this.data).then(res => {
        openNotification('Azure Active Directory ID innhentet', res)
        this.data.AAD = res
      }).catch(() => {
        openNotification('Feil ved innhenting av AAD ID')
      })
    },
    deleteOffice365() {
      delete365API(this.data).then(res => {
        openNotification('Azure Active Directory ID slettet', res)
        this.data.AAD = res
      }).catch(() => {
        openNotification('Feil ved sletting av AAD bruker')
      })
    },
    deactivateOffice365() {
      const email = this.data.companyEmail
      this.data.companyEmail = ''
      this.loading = true
      deactivate365API({ email: email }).then(() => {
        openNotification('Microsoft 365 Bruker Deaktivert', '')
        this.updateUser()
      }).catch(() => {
        this.data.companyEmail = email
        openNotification('Feil ved oprettelse av bruker')
      }).finally(() => {
        this.loading = false
      })
    },
    fjordkraftPortalOK() {
      this.data.fjordkraftPortal = this.data.email
      this.updateUser().then(() => {

      })
      this.removeExisting()
      this.newUsers = false
    },
    async orderTalkmoreB2C() {
      if (this.data.phone && this.data.name && this.data.surname) {
        this.loading = true
        // const to = 'crm.feilmeldinger@telenor.com'
        // const cc = 'mv@talkmore.no'
        const talkmoreNewUser = firestore.collection('tasks').doc('talkmoreNewUser')
        await talkmoreNewUser.set({
          title: 'Opprette nye Talkmore Brukere',
          description: 'Det har blitt bestilt nye brukere på talkmore privat. <br> Vennligst sjekk gjennom og registrer i <a href="https://wd3.myworkday.com/wday/authgwy/telenorgroup/login.htmld?redirect=n">Workday</a>',
          date: String(moment().format('YYYY-MM-DD')),
          type: 'orderTMB2Cuser',
          status: 'to-do',
          subTasksAction: true,
          subTasksActionIcon: 'fa fa-trash',
          hasSubtasks: true,
        })
        await talkmoreNewUser.collection('subtasks').add({
          title: this.data.name.concat(' ', this.data.surname),
          description: `Navn: ${this.data.name}<br>
          Etternavn: ${this.data.surname}<br>
          Telefon: ${this.data.phone}<br>
          Kjønn: ${calculateGender(this.data.personalNumber)}<br>
          Personnummer: ${this.data.personalNumber}<br>`,
        })
        openNotification('Talkmore Privat Bruker Bestilt', '')
        this.data.talkmoreB2C = 'Bruker bestilt'
        this.updateUser()
        this.loading = false
        this.removeExisting()
        this.newUsers = false
        this.editUsers = false
      } else {
        openNotification('Manglende informasjon for å bestile brukere')
      }
    },
    async orderTelenorWorkday() {
      if (this.data.phone && this.data.name && this.data.surname) {
        this.loading = true
        // const to = 'crm.feilmeldinger@telenor.com'
        // const cc = 'mv@talkmore.no'
        const telenorNewUser = firestore.collection('tasks').doc('telenorNewUser')
        await telenorNewUser.set({
          title: 'Opprette nye Telenor Workday Brukere',
          description: 'Det har blitt bestilt nye brukere på talkmore privat. <br> Vennligst sjekk gjennom og registrer i <a href="https://wd3.myworkday.com/wday/authgwy/telenorgroup/login.htmld?redirect=n">Workday</a>',
          date: String(moment().format('YYYY-MM-DD')),
          type: 'orderTMB2Cuser',
          status: 'to-do',
          subTasksAction: true,
          subTasksActionIcon: 'fa fa-trash',
          hasSubtasks: true,
        })
        await telenorNewUser.collection('subtasks').add({
          title: this.data.name.concat(' ', this.data.surname),
          description: `Navn: ${this.data.name}<br>
          Etternavn: ${this.data.surname}<br>
          Telefon: ${this.data.phone}<br>
          Kjønn: ${calculateGender(this.data.personalNumber)}<br>
          Personnummer: ${this.data.personalNumber}<br>`,
        })
        openNotification('Telenor Workday Bruker Bestilt', '')
        this.loading = false
        this.removeExisting()
        this.newUsers = false
        this.editUsers = false
      } else {
        openNotification('Manglende informasjon for å bestile brukere')
      }
    },
    createVismaXML() {
      if (this.data.adress && this.data.bankAccount && this.data.city && this.data.email && this.data.name && this.data.surname && this.data.personalNumber && this.data.zip && this.data.employeeID) {
        const data = this.data
        this.data.startDate = this.data.positions.at(-1).startDate
        vismaOnboarding(data).then(() => {
          openNotification('Visma Bruker opprettet', 'Brukeren må først bli godkjent og lagt inn av lønnansvarlig.')
          this.data.vismaEmployee = 'Bruker Aktiv'
          this.updateUser()
        }).catch(err => openNotification('Feil ved oppretting av Visma bruker', err.message)).finally(() => {
          this.loading = false
          this.removeExisting()
          this.newUsers = false
          this.editUsers = false
        })
      } else {
        openNotification('Mangler info på ansatt for å opprette Visma Bruker')
        console.log(this.data.adress, this.data.bankAccount, this.data.city, this.data.email, this.data.name && this.data.surname && this.data.personalNumber && this.data.zip && this.data.employeeID)
      }
    },
    createTripletexCSV() {
      if (this.data.adress && this.data.bankAccount && this.data.city && this.data.email && this.data.name && this.data.surname && this.data.personalNumber && this.data.zip && this.data.employeeID) {
        const data = this.data
        this.data.startDate = this.data.positions.at(-1).startDate
        createTripletexCSV(data)
        openNotification('Tripletex bruker bestilt')
      } else {
        openNotification('Mangler info på ansatt for å opprette Tripletex Bruker')
        console.log(this.data.adress, this.data.bankAccount, this.data.city, this.data.email, this.data.name && this.data.surname && this.data.personalNumber && this.data.zip && this.data.employeeID)
      }
    },
    async orderSalesforceTelenorFiber() {
      if (this.data.name && this.data.surname && this.data.companyEmail && this.data.phone && this.tnfibersf.startDate && this.tnfibersf.role && this.tnfibersf.systems) {
        this.loading = true
        // const to = 'crm.feilmeldinger@telenor.com'
        // const cc = 'mv@talkmore.no'
        const tnfieldsalesforce = firestore.collection('tasks').doc('tnfieldsalesforceNewUser')
        await tnfieldsalesforce.set({
          title: 'Opprette nye Salesforce brukere for telenor fiber felt',
          description: 'Det har blitt bestilt nye Salesforce brukere for Telenor Fiber Felt. <br> Vennligst sjekk gjennom ansatt listen og trykk på knappen nede for å bestille',
          date: String(moment().format('YYYY-MM-DD')),
          callbacks: [
            {
              fn: 'orderTnSalesforceUsers',
              name: 'Send forespørsel brukere',
              type: 'CompleteTask',
            },
          ],
          hasSubtasks: true,
          project: 'TNFIBER',
          type: 'orderTNFIBERuser',
          icon: 'fa fa-users',
          status: 'to-do',
          subTasksAction: true,
          subTasksActionIcon: 'fa fa-trash',
          lastOrderedBy: this.$store.state.user.name,
        })
        const systems = this.tnfibersf.systems.join(' / ')
        const startDate = String(moment(this.tnfibersf.startDate).format('DD.MM.YYYY'))
        const img = this.data.imageUrl ? this.data.imageUrl : ''
        await tnfieldsalesforce.collection('subtasks').add({
          title: this.data.name.concat(' ', this.data.surname),
          description: `E-post: ${this.data.companyEmail}<br>
                        Telefon: ${this.data.phone}<br>
                        Rolle: ${this.tnfibersf.role}<br>
                        System: ${systems}<br>
                        Start Dato: ${startDate}<br>
                        Kommentar: ${this.tnfibersf.comment}<br>
                        Bestilt av: ${this.$store.state.user.name}
          `,
          img,
          fnValues: {
            uid: this.data.uid,
            AAD: this.data.AAD,
            employeeID: this.data.employeeID,
            name: this.data.name,
            surname: this.data.surname,
            email: this.data.companyEmail,
            phone: this.data.phone,
            startDate,
            comment: this.tnfibersf.comment,
            role: this.tnfibersf.role,
            systems,
          },
        })
        openNotification('Telenor Fiber Felt Salesforce Bruker Bestilt', '')
        this.data.telenorFiber = 'Bruker bestilt'
        this.updateUser()
        this.loading = false
        this.newUsers = false
        this.editUsers = false
      } else {
        openNotification('Manglende informasjon for å bestile brukere')
      }
    },
    async orderIDTelenorFiber() {
      if (this.data.name && this.data.surname && this.data.companyEmail && this.data.imageUrl && this.tnfiberId.fileList && this.tnfiberId.delivery) {
        this.loading = true
        // const to = 'crm.feilmeldinger@telenor.com'
        // const cc = 'mv@talkmore.no'
        const idCard = await this.handleUpload(this.data.name.concat(' ', this.data.surname, ' ID kort'))
        const tnfieldsalesforce = firestore.collection('tasks').doc('ordertnfieldID')
        await tnfieldsalesforce.set({
          title: 'Bestille nye ID kort for telenor fiber felt',
          description: 'Det har blitt bestilt nye ID kort for Telenor Fiber Felt. <br> Vennligst sjekk gjennom ansatt listen og trykk på knappen nede for å bestille',
          date: String(moment().format('YYYY-MM-DD')),
          callbacks: [
            {
              fn: 'orderTnID',
              name: 'Send forespørsel ID kort',
              type: 'CompleteTask',
            },
          ],
          hasSubtasks: true,
          project: 'TNFIBER',
          type: 'orderTNFIBERuser', // needs to be changed after
          icon: 'fa fa-users',
          status: 'to-do',
          subTasksAction: true,
          subTasksActionIcon: 'fa fa-trash',
          lastOrderedBy: this.$store.state.user.name,
        })
        const profileImg = {
          url: this.data.imageUrl,
          ref: `users/profile/${this.data.uid}`,
        }
        // try to find profile img url from url:
        // const bucketName = downloadUrl.split('/')[3];
        // const objectPath = decodeURIComponent(downloadUrl.split('?')[0].split('/o/')[1]);
        await tnfieldsalesforce.collection('subtasks').add({
          title: this.data.name.concat(' ', this.data.surname),
          description: `Bursdag: ${this.data.birthdate}<br>
                        <a href="${this.data.imageUrl}" target="_blank">Bilde</a><br>
                        <a href="${idCard.url}" target="_blank">Legitimasjon</a><br>
                        Sendes til: ${this.tnfiberId.delivery}<br>
                        Bestilt av: ${this.$store.state.user.name}
          `,
          img: profileImg.url,
          fnValues: {
            fullName: this.data.fullName,
            birthdate: this.data.birthdate,
            profileImg,
            phone: this.data.phone,
            email: this.data.companyEmail,
            idImg: {
              url: idCard.url,
              ref: idCard.ref,
            },
            delivery: this.tnfiberId.delivery,
          },
        })
        openNotification('Telenor Fiber Felt Salesforce Bruker Bestilt', '')
        this.data.telenorFiberID = 'Bruker bestilt'
        this.updateUser()
        this.loading = false
        this.newUsers = false
        this.editUsers = false
      } else {
        openNotification('Manglende informasjon for å bestile brukere')
      }
    },
    sendUserEmail() {
      if (this.data.email && this.data.name) {
        this.loading = true
        const to = String(this.data.email)
        const cc = ''
        const subject = 'Dine brukere i SkyGruppen'
        const body = `<html><body><p>Hei ${this.data.name},<br>
        Her har du oversikt over brukerne du har i Skygruppen.<br><br>
        ${this.data.companyEmail ? '<strong>MICROSOFT 365:</strong> For å logge inn på pcene våres samt logge inn på <a href="portal.skygruppen.no">Sky Portal</a>, bruker du Microsoft 365 brukeren din<br><br>Bruker: '.concat(this.data.companyEmail, '<br>Midlertidig passord: SkyGruppen2021!<br><br>Ved første inlogging blir du spurt om en del spørsmål for sikkerhet. Dette må du kun gjøre en gang.<br><br>') : ''}
        Vi anbefaler å gjøre deg kjent med <a href="portal.skygruppen.no">Sky Portalen</a>. Her får du tilgang til personalomboken, rapporter og mye annet.<br><br>
        <strong>SLACK:</strong> for internkommunikasjon så bruker vi Slack. Du skal ha fått en epost på ${this.data.email} vedrørende dette. Bare følg instruskjoner der.<br>
        Om du ikke har fått e-post fra Slack vennligst hør med din nærmeste leder.
        <br><br>
        Slack applikasjonen skal være allerede lastet ned på pc-ene og iPadene, men du kan også laste det ned på mobilen din.<br><br>
        ${this.data.leadDesk ? '<strong>Leaddesk:</strong> Som du kanskje har sett nå, så bruker vi Leaddesk for å utføre samtalene. Du har nå fått en bruker:<br>Bruker: '.concat(this.data.leadDesk, '<br>Passord: Lead1234<br><br>Du kan logge inn fra login.leaddesk.com eller fra applikasjonen Leaddesk som ligger på PCene.<br><br>') : ''}
        ${this.data.fjordkraftPortal ? '<strong>FJORDKRAFT PORTAL:</strong> For å registrere samtalene når du først får ett salg så gjør du dette på fjordkraft.no/skycall. Ved første innlogginng så taster du glemt passord. Brukernavnet ditt er '.concat(this.data.fjordkraftPortal, 'Når du resetter passordet så kommer e-posten ganske ofte i søppelposten, så vennligst sjekk der og.<br><br>') : ''}
        ${this.data.vismaEmployee ? '<strong>VISMA</strong> i bruker visma for lønnskjøring, vennligst last ned appen Visma Employee (<a href="https://itunes.apple.com/us/app/visma-employee/id919085610?mt=8">App Store</a> &nbsp;/ <a href="https://play.google.com/store/apps/details?id=com.visma.vme.payslip">Google Play</a>). Du kan også se på lønnslippene ved å gå til <a href="home.visma.com">Visma NET</a>. Brukeren er denne e-posten '.concat(this.data.email, 'som du ga ved onboarding.<br><br>') : ''}
        Om det er noe som helst du lurer på er det bare å kontakte din nærmeste leder.<br>
        <p>Med vennlig hilsen,</p>
        <p>IT avdeling<br>
        Skygruppen AS</p></body></html>`
        console.log(to, cc, subject, body)
        sendEmail(to, cc, subject, body).then(() => {
          openNotification('E-post med brukere sendt til '.concat(to), '')
        }).catch(err => openNotification('Feil ved sending av e-post', err)).finally(() => {
          this.loading = false
        })
      } else {
        openNotification('Mangler e-post til ansatt')
      }
    },
    handleChange(value) {
      const username = this.data.name.slice(0, 3).concat('.', this.data.surname.slice(0, 3)).toLowerCase()
      if (value === 'Leaddesk Fjordkraft') {
        this.office365 = false
        this.leadDesk = true
        this.talkmoreB2C = false
        this.leadDeskUser = username
        this.fjordkraftPortal = false
        this.telenorFiberSalesforce = false
        this.telenorFiberID = false
        this.tripletexManual = false
      } else if (value === 'Microsoft 365') {
        this.office365 = true
        this.leadDesk = false
        this.talkmoreB2C = false
        this.fjordkraftPortal = false
        this.telenorFiberSalesforce = false
        this.telenorFiberID = false
        this.vismaEmployee = false
        this.companyEmail = username.concat('@', 'skycallas.no')
        this.tripletexManual = false
      } else if (value === 'Talkmore Privat') {
        this.leadDesk = false
        this.talkmoreB2C = true
        this.office365 = false
        this.fjordkraftPortal = false
        this.telenorFiberSalesforce = false
        this.telenorFiberID = false
        this.vismaEmployee = false
        this.tripletexManual = false
      } else if (value === 'Webtabellen Fjordkraft') {
        this.leadDesk = false
        this.talkmoreB2C = false
        this.office365 = false
        this.fjordkraftPortal = true
        this.telenorFiberSalesforce = false
        this.telenorFiberID = false
        this.vismaEmployee = false
        this.tripletexManual = false
      } else if (value === 'Telenor Fiber Salesforce') {
        this.leadDesk = false
        this.talkmoreB2C = false
        this.office365 = false
        this.fjordkraftPortal = false
        this.telenorFiberSalesforce = true
        this.telenorFiberID = false
        this.vismaEmployee = false
        this.tripletexManual = false
      } else if (value === 'Telenor Fiber ID kort') {
        this.leadDesk = false
        this.talkmoreB2C = false
        this.office365 = false
        this.fjordkraftPortal = false
        this.telenorFiberSalesforce = false
        this.telenorFiberID = true
        this.vismaEmployee = false
        this.tripletexManual = false
      } else if (value === 'Visma Employee') {
        this.leadDesk = false
        this.talkmoreB2C = false
        this.office365 = false
        this.fjordkraftPortal = false
        this.telenorFiberSalesforce = false
        this.telenorFiberID = false
        this.vismaEmployee = true
        this.tripletexManual = false
      } else if (value === 'Tripletex Manual') {
        this.leadDesk = false
        this.talkmoreB2C = false
        this.office365 = false
        this.fjordkraftPortal = false
        this.telenorFiberSalesforce = false
        this.telenorFiberID = false
        this.vismaEmployee = false
        this.tripletexManual = true
      } else if (value === 'TelenorWorkday') {
        this.leadDesk = false
        this.talkmoreB2C = false
        this.office365 = false
        this.fjordkraftPortal = false
        this.telenorFiberSalesforce = false
        this.telenorFiberID = false
        this.vismaEmployee = false
        this.tripletexManual = false
        this.telenorWorkday = true
      }
    },
    handleBlur() {
      console.log('blur')
    },
    handleFocus() {
      console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    beforeUpload(file) {
      this.tnfiberId.fileList = [file]
      return false
    },
    async handleUpload(fileName) {
      const { fileList } = this.tnfiberId
      const file = fileList[0]
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      }
      const compressedFile = await imageCompression(file, options)
      console.log('Compressed', compressedFile)
      console.log('uncompressed', file)
      let uploadedFile
      return storage.ref('/tasks/tasksAttachments').child(fileName).put(compressedFile).then((snapshot) => {
        console.log(snapshot)
        uploadedFile = {
          ref: snapshot.metadata.fullPath,
          name: snapshot.metadata.name,
          timeCreated: snapshot.metadata.timeCreated,
        }
        return snapshot.ref.getDownloadURL()
      }).then(url => {
        uploadedFile.url = url
        return uploadedFile
      }).catch(err => {
        console.log(err)
        throw new Error('could not uplaod file')
      })
    },
  },
}
</script>

<style scoped>
  .ant-input.validfield {
    border-color: green!important
  }
  .ant-input.invalidfield {
    border-color: red!important
  }
</style>
