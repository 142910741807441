<template>
  <div v-if="data">
    <div class="row">
        <div class="col-lg-12">
          <h2>Ny Stilling
          <a-button type="secondary" style="float: right;" class="mt-3" @click="$emit('cancelReactivation')">Avbryt</a-button>
          </h2>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <kit-general-10v1 :name="data.fullName" :position="data.title" :department="data.department" :uid="data.uid" :companies="data.companies" :type="data.type"/>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card">
          <div class="card-body">
            <about-user-form :data="data" mode="notEdit" />
          </div>
          </div>
        </div>
      </div>
      <a-form @submit.prevent="newPosition(data, position)">
        <div class="card" v-if="data.status !== 'active'">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Kontaktinfo</h5>
          <div class="row">
            <div class="col-md-6">
                <a-form-item :label="$t('userInfo.email')">
                <a-input :placeholder="$t('userInfo.email')" type="email" v-model="data.email" />
                </a-form-item>
            </div>
            <div class="col-md-6">
                <a-form-item :label="$t('userInfo.phone')">
                <a-input :placeholde="$t('userInfo.phone')" v-model="data.phone" />
                </a-form-item>
            </div>
            <div class="col-md-6">
                <a-form-item :label="$t('userInfo.adress')">
                <a-input :placeholder="$t('userInfo.adress')" v-model="data.adress"/>
                </a-form-item>
            </div>
            <div class="col-md-2">
                <a-form-item :label="$t('userInfo.zip')">
                <a-input v-model="data.zip" :maxLength="4" @change="data.city = findCity(data.zip)"/>
                </a-form-item>
            </div>
            <div class="col-md-4">
                <a-form-item :label="$t('userInfo.city')">
                  {{ data.city }}
                </a-form-item>
            </div>
          </div>
        </div>
        </div>
        <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Ansettelsestype</h5>
          <div class="row mb-3" v-if="$store.state.user.companies.length > 1">
            <div class="col-md-6">
              <a-form-item label="Velg selskap">
                <a-select :options="companies" v-model="selectedCompany"/>
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <tabSelector v-model="position.type" :values="positionsSelector.filter(el => el.company === selectedCompany)" />
            </div>
            <div class="col-md-4">
            </div>
            <!-- <div class="col-md-4">
            </div> -->
          </div>
        </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="text-black mt-2 mb-3">Kontraktsdetaljer</h5>
            <div class="row">
              <div class="col-md-4" v-if="['HRcreateUsersAll'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
                <a-form-item label="Avdeling">
                  <a-select
                    v-model="position.department"
                    :options="avDepartments"
                  />
                </a-form-item>
              </div>
              <div class="col-lg-3">
                <a-form-item :label="$t('userInfo.startDate')" :required="true">
                  <a-date-picker :default-value="moment(new Date(), 'YYYY-MM-DD')"  v-model="position.startDate"/>
                </a-form-item>
              </div>
            </div>
            <div class="row" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <div class="col-md-4">
                <a-form-item label="Stillingsprosent (i %)" :required="true">
                  <a-input
                    :value="position.percentage"
                    placeholder="Prosent Stilling"
                    :max-length="3"
                  />
                </a-form-item>
              </div>
              <div class="col-md-4">
                <a-form-item label="Lønnsmodell">
                  <a-select v-model="position.salary.type" :options="salaryTypes"/>
                </a-form-item>
              </div>
              <div class="col-md-4">
                <a-form-item label="Garanti / Fastlønn (i kr)" :required="true">
                  <a-input
                    :value="position.salary.amount"
                    placeholder="Månedslønn"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
        <div class="card-body">
          <h5 class="text-black mt-2 mb-3">Kontrakt</h5>
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Lage Kontrakt?">
                <a-radio-group v-model="sendContract">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-lg-6" v-if="data.status === 'active'">
                <a-form-item label="Avslutte forrige stillinger">
                  <a-radio-group v-model="position.endPrevious">
                    <a-radio :style="radioStyle" :value="true">
                    Ja
                    </a-radio>
                    <a-radio :style="radioStyle" :value="false">
                    Nei
                    </a-radio>
                </a-radio-group>
                </a-form-item>
              </div>
            </div>
        </div>
        </div>
        <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3" :loading="loadingReactivation">Lag Stilling</a-button>
      </a-form>
      <!-- <div v-if="newPosition">
        <h5 class="text-black mt-5 mb-3">
          <strong>Ny stilling</strong>
        </h5>
        <tab-selector v-model="position.type" :values="positionsSelector" @change="selectProject" />
        <div class="row" v-if="position.type === 'custom'">
          <div class="col-md-6">
            <a-form-item label="Stillingsnavn">
            <a-input placeholder="Stillingsnavn" v-model="customType"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Selskap">
            <a-input placeholder="Selskap" v-model="position.company"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Prosjekt">
            <a-input placeholder="Prosjekt" v-model="position.project"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Stillingstype">
            <a-input placeholder="Fulltid/Deltid" v-model="position.employmentType"/>
            </a-form-item>
          </div>
        </div>
        <div class="row">
        <div class="col-lg-6">
            <a-form-item :label="$t('userInfo.startDate')" :required="true">
            <a-date-picker :default-value="moment(new Date(), 'YYYY-MM-DD')"  v-model="position.startDate"/>
            </a-form-item>
        </div>
        </div>
        <a-button style="width: 200px;" type="primary"  class="mr-3 mt-3" @click="createPosition">Opprett Ny Stilling</a-button>
      </div> -->
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import moment from 'moment'

import KitGeneral10v1 from '@/components/kit/widgets/General/10v1/index'
import AboutUserForm from '@/components/custom/forms/userinfo'
import tabSelector from '@/components/custom/visual/tabSelector'

import { createPosition, findCity, createContract, updateEmployee, selectAvDepartments, salaryTypes } from '@/services/common/hr'

export default {
  components: {
    KitGeneral10v1,
    AboutUserForm,
    tabSelector,
  },
  props: ['data'],
  data() {
    return {
      salaryTypes,
      offboarding: {},
      loadingReactivation: false,
      sendContract: true,
      position: {
        endPrevious: true,
        contractId: '',
        salary: {},
      },
      positionsSelector: [],
      contractTypes: [],
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      avDepartments: [],
      companies: [],
      selectedCompany: '',
    }
  },
  mounted() {
    this.$store.state.user.companies.forEach(company => {
      firestore.collection('settings').doc('companies').collection('companies').doc(company).get()
        .then(doc => {
          this.companies.push({
            label: doc.data().displayName,
            value: doc.id,
          })
        })
    })
    if (this.$store.state.user.companies.length === 1) {
      this.selectedCompany = this.$store.state.user.companies[0]
    }
    firestore.collection('settings').doc('projects').collection('projects').get()
      .then(snapshot => {
        let i = 1
        snapshot.forEach(doc1 => {
          if ([doc1.data().company].some(r => this.$store.state.user.companies.indexOf(r) >= 0)) {
            firestore.collection('settings').doc('contracts').collection('contracts').where('project', '==', doc1.id).orderBy('title', 'asc').get()
              .then(snapshot => {
                const children = []
                snapshot.forEach(doc2 => {
                  if ((['HRcreateUsersDepartment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0) && [this.$store.state.user.department].some(r => doc2.data().department.indexOf(r) >= 0)) ||
                      ['HRcreateUsersAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
                    if ([doc2.data().role].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
                      const child = {
                        value: doc2.data().title,
                        department: doc2.data().department,
                        company: doc2.data().company,
                        project: doc1.data().name,
                        projectID: doc1.data().id,
                        docTemplate: doc2.data().docTemplate,
                        defaultRoles: doc2.data().defaultRoles,
                        positionTitle: doc2.data().positionTitle,
                        employmentType: doc2.data().employmentType,
                        contractId: doc2.id,
                        percentage: doc2.data().percentage,
                        salary: doc2.data().salary,
                        occupationCode: doc2.data().occupationCode,
                      }
                      this.contractTypes.push(child)
                      children.push(child)
                    }
                  }
                })
                if (children.length) {
                  this.positionsSelector.push({
                    title: doc1.data().name,
                    company: doc1.data().company,
                    key: i,
                    children,
                  })
                  i += 1
                }
              })
          }
        })
        console.log(this.positionsSelector)
      })
  },
  methods: {
    moment,
    findCity,
    createContract,
    selectProject (e) {
      console.log(e)
      const obj = this.contractTypes.find(o => o.value === e)
      console.log(obj)
      this.position.project = obj.project
      this.data.title = obj.positionTitle
      this.data.roles = obj.defaultRoles
      if (['HRcreateUsersAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        this.avDepartments = selectAvDepartments(obj.department)
      } else {
        this.position.department = this.$store.state.user.department
      }
      this.position.projectID = obj.projectID
      this.position.company = obj.company
      this.position.employmentType = obj.employmentType
      this.position.contractId = obj.contractId
      this.position.occupationCode = obj.occupationCode
      this.position.percentage = obj.percentage
      this.position.salary = obj.salary
      console.log(this.position)
    },
    async newPosition(data, position) {
      // wrap in try catch
      this.data.department = this.position.department
      this.data.project = this.position.projectID
      console.log('new Position Started', data, position)
      if (data.status === 'inactive') {
        data.status = 'onboarding'
      }
      await createPosition(data, position)
      console.log('Position Created', data)
      if (this.sendContract) {
        await createContract(data, position, data.positions.length - 1)
      }
      await updateEmployee(data)
      this.$emit('cancelReactivation')
    },
  },
  watch: {
    // whenever question changes, this function will run
    'position.type'(newValue) {
      this.selectProject(newValue)
    },
  },
}
</script>
